import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import AuthorizationParams from "./AuthorizationParams";
import {NavigationStructure} from "../Components/Navigation/NavigationStructure";

export const useAuthentication = (logoutOnFail = true) => {
	const [token, setToken] = useState<string>('');
	const [isAdmin, setIsAdmin] = useState(false);
	const {
		getAccessTokenSilently,
		isAuthenticated,
		user,
		logout
	} = useAuth0();

	useEffect(() => {
		if (user) {
			if (user['https://hub.gebrueder-saleh.de/roles']?.includes('Admin')) setIsAdmin(true);
		}
	}, [user])

	useEffect(() => {
		if (isAuthenticated) {
			getAccessTokenSilently({
				authorizationParams: AuthorizationParams,
			}).then((res) => {
				if (res) setToken(res);
			}).catch(_ => {
				if (logoutOnFail) {
					logout({
						openUrl: () => window.location.replace(window.location.origin + NavigationStructure.logout.url)
					});
				}
			})
		}
	}, [isAuthenticated, token]);

	return {
		token,
		isAuthenticated,
		isAdmin,
		logout
	}
}